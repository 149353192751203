<template>
  <edit-template class="charge-subject" @confirm="submit" :confirm-loading="loading">
    <el-form ref="form" :model="form" label-width="120rem" :rules="rules">
      <el-form-item label="科目名称" prop="name">
        <el-input placeholder="请输入" v-model="form.name" maxlength="10"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option v-for="item in typeList" :key="item.id" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="金额" prop="price">
        <el-input-number placeholder="请输入" v-model="form.price" :max="10000000" :min="0" :step="0.01" :precision="2"></el-input-number>
      </el-form-item>
    </el-form>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit"
export default {
  _config:{"route":{"path":"add","meta":{"title":"新增"}}},
  components:{EditTemplate},
  data() {
    return {
      loading:false,
      form: {
        name:null,
        type:null,
        price:null,
      },
      rules:{
        name: [
          { required: true, message: '请输入科目名称', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        price: [
          { required: true, message: '请输入金额', trigger: 'blur' },
        ],
      },
      typeList:[]
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    getData(){
      this.$_register.get("api/recruit/common/fee-type").then(res=>{
        this.typeList = res.data.data;
      })
    },
    submit(){
      this.$refs.form.validate(res=>{
        if (!res)return;
        this.loading = true;
        this.$_register.post("api/finance/fee/store",this.form).then(res=>{
          this.$router.back();
        }).catch(()=>this.loading = false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>


@media only screen and (min-width: 1140px){
  ::v-deep .el-form .el-input{
    min-width:180rem;
  }
}

</style>
